import "./styles.scss";

import React, { useState } from "react";

const Accordion = ({ item, number }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className={`accordion${accordionOpen ? " open" : ""}`}>
      <div className="accordion__number">
        <span>{number}</span>
      </div>
      <div className="accordion__wrapper">
        <button
          type="button"
          className="accordion__top"
          onClick={() => setAccordionOpen(!accordionOpen)}
        >
          <span className="accordion__title">{item?.title}</span>
          <div className="accordion__icon">
            <span></span>
            <span></span>
          </div>
        </button>
        <div className="accordion__bottom">
          <p className="accordion__text">{item?.text}</p>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
