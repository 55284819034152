import React from "react";
import scrollToElement from "scroll-to-element";
import { Link } from "gatsby";

import { Layout } from "../components";
import FormLandingPages from "../components/FormLandingPages";
import Accordion from "../components/Accordion";

import locales from "../constants";

const faq_content_first = [
  {
    title: "Spotkanie wstępne",
    text: "Na początku zaplanujemy spotkanie z klientem, aby omówić jego potrzeby i cele integracji. W tym etapie zbierzemy wszelkie niezbędne informacje dotyczące systemów, które mają zostać zintegrowane, wymagań klienta oraz przeanalizujemy dokumentację projektu. Jeśli nie posiadamy dokumentacji, możemy ją przygotować w ramach studium wykonalności projektu i analizy wymagań.",
  },
  {
    title: "Analiza wymagań",
    text: "Na podstawie zebranych informacji przeprowadzimy analizę wymagań i opracujemy szczegółowy plan integracji. W tym etapie określimy, jakie typy danych będą przekazywane między systemami oraz jakie są wymagania dotyczące czasu odpowiedzi i bezpieczeństwa.",
  },
  {
    title: "Projektowanie",
    text: "Po zatwierdzeniu planu integracji przejdziemy do projektowania rozwiązania. W tym etapie określimy, jakie narzędzia i technologie będą używane do integracji, a także opracujemy schematy przepływu danych.",
  },
  {
    title: "Implementacja",
    text: "Gdy projekt zostanie zatwierdzony, przystąpimy do implementacji. W tym etapie zintegrujemy systemy i zaprogramujemy niezbędne funkcje. Wszystkie testy będą również przeprowadzane na bieżąco, aby upewnić się, że integracja działa prawidłowo.",
  },
  {
    title: "Testowanie i optymalizacja",
    text: "Po zakończeniu implementacji przeprowadzimy dokładne testowanie integracji. W tym etapie weryfikujemy, czy dane są przesyłane poprawnie oraz czy integracja działa zgodnie z wymaganiami klienta. Po zakończeniu testów przeprowadzimy optymalizację, aby poprawić wydajność i szybkość przesyłania danych między systemami.",
  },
  {
    title: "Wdrożenie",
    text: "Gdy integracja zostanie przetestowana i zoptymalizowana, przystąpimy do wdrożenia. W tym etapie upewnimy się, że wszystkie dane zostały przesłane poprawnie i integracja działa zgodnie z wymaganiami klienta na serwerach docelowych.",
  },
  {
    title: "Szkolenie i wsparcie",
    text: "Po wdrożeniu systemu przeprowadzimy szkolenie dla użytkowników, aby umożliwić im korzystanie z nowych funkcji. Zapewnimy również wsparcie techniczne, aby pomóc w rozwiązywaniu ewentualnych problemów.",
  },
  {
    title: "Monitorowanie i rozwijanie",
    text: "Po wdrożeniu systemu będziemy nadal monitorować integrację, aby upewnić się, że działa ona zgodnie z wymaganiami klienta. W tym etapie będziemy również rozwijać i udoskonalać integrację, aby sprostać zmieniającym się potrzebom klienta.",
  },
];

const faq_content_second = [
  {
    title: "Identyfikacja wymagań",
    text: "Rozpoczniemy od analizy potrzeb klienta oraz zrozumienia kluczowych aspektów systemów, które mają zostać zintegrowane. Wspólnie z klientem zidentyfikujemy cele i oczekiwania związane z integracją.",
  },
  {
    title: "Eksploracja możliwości",
    text: "Przystąpimy do badania możliwości technicznych, sprawdzając różne narzędzia, technologie i podejścia, które mogą być użyte do zintegrowania systemów. Współpracując z klientem, będziemy regularnie prezentować wyniki naszych badań oraz dyskutować o potencjalnych rozwiązaniach.",
  },
  {
    title: "Wybór optymalnego rozwiązania",
    text: "Na podstawie wyników eksploracji oraz preferencji klienta, wybierzemy najbardziej odpowiednie rozwiązanie do implementacji.",
  },
  {
    title: "Implementacja i adaptacja",
    text: "W trakcie implementacji, będziemy elastycznie dostosowywać się do napotkanych wyzwań oraz ewentualnych zmian w wymaganiach klienta. Regularne komunikowanie postępów oraz współpraca z klientem pozwolą na bieżące wprowadzenie niezbędnych modyfikacji w projekcie.",
  },
  {
    title: "Testowanie i weryfikacja",
    text: "Po zakończeniu implementacji, przeprowadzimy testy oraz weryfikację, aby upewnić się, że opracowane rozwiązanie spełnia oczekiwania klienta i działa zgodnie z założonymi celami.",
  },
  {
    title: "Dalsze rozwijanie i udoskonalanie",
    text: "Po wdrożeniu opracowanego rozwiązania, będziemy kontynuować jego rozwój i udoskonalanie, aby sprostać ewentualnym zmianom w potrzebach klienta oraz zidentyfikowanym możliwościom optymalizacji.",
  },
];

const process_content = [
  {
    title: "Liczba systemów wymieniających dane",
    desc: "Czy integracja obejmuje tylko dwa systemy, czy też więcej? Czy istnieje możliwość, że w przyszłości będzie konieczne dodanie kolejnych systemów do integracji?",
  },
  {
    title: "Zarządzanie systemami objętymi integracją",
    desc: "Kto jest odpowiedzialny za zarządzanie poszczególnymi systemami? Czy wszystkie są kontrolowane przez jedną firmę, czy też niektóre z nich są obsługiwane przez klientów, dostawców lub inne instytucje?",
  },
  {
    title: "Sposób wymiany danych",
    desc: "Czy wymiana danych ma odbywać się automatycznie, czy na żądanie? Jeśli ma być automatyczna, to czy musi działać w czasie rzeczywistym, czy też może być realizowana w określonych interwałach czasowych? A może wymiana danych ma być inicjowana w wyniku konkretnej akcji jednego z systemów lub interwencji operatora?",
  },
  {
    title: "Kierunek przepływu danych",
    desc: "Czy dane mają być przesyłane tylko z systemu A do systemu B, czy też w obie strony?",
  },
  {
    title: "Rozstrzyganie konfliktów danych",
    desc: "Czy może wystąpić sytuacja, w której dane w jednym systemie będą sprzeczne z danymi w drugim systemie? Jeśli tak, to który system ma pierwszeństwo? A może rozwiązanie konfliktów powinno być pozostawione osobie odpowiedzialnej za systemy?",
  },
  {
    title: "Dostępność interfejsów wymiany danych",
    desc: 'Czy wszystkie systemy objęte projektem integracyjnym oferują jakąś formę interfejsu wymiany danych? Czy dostępne są API, czy też konieczne będzie bezpośrednie połączenie z bazą danych lub zastosowanie innego mechanizmu wymiany plików? Czy istnieje aktualna dokumentacja API (jeśli istnieje), czy też będzie konieczne samodzielne "odszyfrowanie" interfejsu?',
  },
  {
    title: "Wymagania dotyczące bezpieczeństwa",
    desc: "Jakie są oczekiwania klienta w zakresie zabezpieczenia przesyłanych danych oraz procesów autoryzacji i uwierzytelniania?",
  },
  {
    title: "Dostosowanie do przepisów prawnych",
    desc: "Czy integracja spełnia wszelkie wymagania prawne, takie jak przepisy dotyczące ochrony danych osobowych (np. RODO)?",
  },
  {
    title: "Skalowalność i elastyczność",
    desc: "Czy opracowywane rozwiązanie integracyjne będzie w stanie skalować się wraz ze wzrostem liczby użytkowników, danych czy zasobów systemowych? Czy jest wystarczająco elastyczne, aby dostosować się do przyszłych zmian w systemach lub procesach biznesowych?",
  },
  {
    title: "Koszty i czas realizacji",
    desc: "Jaki jest przewidywany budżet i harmonogram projektu? Czy są realistyczne, a jednocześnie adekwatne do zakresu i złożoności integracji?",
  },
  {
    title: "Planowanie awaryjne i odzyskiwanie danych",
    desc: "Jakie są procedury awaryjne oraz plany dotyczące odzyskiwania danych w przypadku awarii systemów lub utraty danych?",
  },
  {
    title: "Współpraca z zewnętrznymi zespołami technicznymi",
    desc: "Jak będzie wyglądała komunikacja i współpraca z zespołami technicznymi zarządzającymi systemami objętymi integracją? Czy są potrzebne dodatkowe narzędzia lub procesy wspierające współpracę?",
  },
  {
    title: "Migracja danych i konwersja",
    desc: "Czy konieczna jest migracja danych z istniejących systemów do nowego środowiska integracyjnego? Jeśli tak, to jak zostanie przeprowadzona konwersja danych oraz jakie są plany dotyczące walidacji przekształconych danych?",
  },
];

const SynchLandingPage = ({ pageContext: { slug } }) => {
  const lang = "pl";

  const calc_content = [
    {
      icon: require("../assets/img/synch/clock.svg"),
      title: "Rozliczenie godzinowe",
      desc: `<strong>To najczęściej stosowany sposób rozliczenia projektów. Klient płaci wykonawcy za każdą przepracowaną godzinę.</strong> Zaletą takiego systemu jest możliwość dokładnego śledzenia czasu poświęconego na projekt i elastyczność w kwestii modyfikacji zakresu prac. Dodatkowo, klient płaci tylko za faktycznie wykonaną pracę.`,
    },
    {
      icon: require("../assets/img/synch/target.svg"),
      title: "Rozliczenie za wykonanie określonego celu (milestone)",
      desc: `<strong>Ten sposób rozliczenia polega na podziale projektu na mniejsze cele, zwane kamieniami milowymi (milestones). Rozliczenie następuje dopiero po osiągnięciu konkretnego celu.</strong> Zaletą takiego rozliczenia jest lepsza kontrola budżetu i minimalizacja ryzyka, gdyż klient płaci tylko w momencie, gdy otrzyma już pewną wartość.`,
    },
    {
      icon: require("../assets/img/synch/cash.svg"),
      title: "Rozliczenie ryczałtowe",
      desc: `<strong>To sposób rozliczenia, w którym klient płaci wykonawcy określoną z góry sumę, a wykonawca ma obowiązek zrealizować projekt do określonego terminu.</strong> Zaletą takiego rozliczenia jest sztywna kwota jednak minusem, że specyfikacja musi być ultra dokładna. Wszelkie niedoprecyzowania podlegają dodatkowemu rozliczeniu a podczas realizacji nie ma możliwości zmian i usprawnień. `,
    },
  ];

  return (
    <Layout
      seo={{
        title: "Dedykowane synchronizatory i integracje systemów",
        href: slug,
        lang: "pl",
        ogImage: require("../assets/img/synch/Synchronizatory_og_image.jpg"),
      }}
      // langSwitch={{
      //   langKey: "en",
      //   langSlug: "/en/ux-audit/",
      // }}
      header={{ icons: "#E9B941" }}
    >
      <section className="container-fluid synch__hero">
        <h1>
          Dedykowane synchronizatory <br />i integracje systemów
        </h1>
        <button
          className="scrollPortfolio"
          onClick={() => scrollToElement("#intro")}
          aria-label="Scroll to intro"
        />
      </section>

      <section className="container-fluid synch__systems" id="intro">
        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <p className="synch__systems-intro">
            Specjalizujemy się w tworzeniu dedykowanych synchronizatorów oraz
            integracji systemów.
          </p>
          <div className="row synch__systems-row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="synch__systems-desc">
                <p>
                  <strong>
                    Nasza firma od ponad 10 lat specjalizuje się w tworzeniu
                    dedykowanych synchronizatorów oraz integracji systemów.
                  </strong>{" "}
                  W ciągu ostatnich lat z powodzeniem współpracowaliśmy z
                  klientami z różnych branż, dostarczając im rozwiązania na
                  najwyższym poziomie. Jesteśmy dumni z naszego zespołu wysoko
                  wykwalifikowanych programistów i specjalistów od integracji,
                  którzy wspólnie tworzą rozwiązania, które usprawniają i
                  zwiększają wydajność procesów biznesowych naszych klientów.
                </p>
              </div>
            </div>
            <div className="col-md-6 pl-md-0 order-1 order-md-2">
              <div className="synch__systems-img">
                <img src={require("../assets/img/synch/zdj3.jpg")} alt="" />
              </div>
            </div>
          </div>
          <div className="row synch__systems-row">
            <div className="col-md-6 pr-md-0">
              <div className="synch__systems-img">
                <img src={require("../assets/img/synch/zdj1.jpg")} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="synch__systems-desc">
                <p>
                  <strong>
                    Specjalizujemy się w indywidualnych integracjach i jesteśmy
                    w stanie napisać integracje z każdym dostawcą, jeśli tylko
                    udostępnia on dane w postaci plików XML, CSV lub API o
                    dowolnej strukturze.{" "}
                  </strong>
                  Poproś swojego dostawcę o dostęp do przykładowych danych i
                  dokumentacji, a my zintegrujemy Cię z nim, tworząc
                  zautomatyzowany proces dodawania i aktualizowania np. bazy
                  produktów wg ustalonych zasad. Jesteśmy elastyczni i
                  dostosowujemy się do wymagań naszych klientów. Oprócz
                  integracji, oferujemy również tworzenie dedykowanego
                  oprogramowania dla firm, dostosowanego do ich specyficznych
                  potrzeb.
                </p>
              </div>
            </div>
          </div>
          <div className="row synch__systems-row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="synch__systems-desc">
                <p>
                  <strong>
                    Przygotowujemy każdy projekt od podstaw, ściśle
                    współpracując z naszymi klientami, aby zapewnić dostarczenie
                    rozwiązania, które spełni ich oczekiwania.
                  </strong>{" "}
                  Pracujemy w zespole, aby zapewnić najlepsze rezultaty, a nasi
                  programiści są zawsze gotowi, by pomóc w razie jakichkolwiek
                  pytań lub problemów. Nasza metodyka pracy zapewnia wysoką
                  jakość i terminowość realizacji projektów.
                </p>
              </div>
            </div>
            <div className="col-md-6 pl-md-0 order-1 order-md-2">
              <div className="synch__systems-img">
                <img src={require("../assets/img/synch/zdj2.jpg")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid synch__faq">
        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <p className="synch__faq-intro">
            Jak wygląda proces realizacji gdy dokumentacja jest możliwa do
            opracowania?
          </p>
          <div className="synch__faq-list">
            {faq_content_first.map((item, index) => (
              <Accordion key={index} item={item} number={index + 1} />
            ))}
          </div>
          <p className="synch__faq-intro">
            W przypadku, gdy dopiero pracujemy nad opracowaniem rozwiązania, a
            dokumentację tworzymy na bazie sukcesywnie dostarczanych danych oraz
            bieżącego analizowania możliwości, proces współpracy wygląda
            następująco:
          </p>
          <div className="synch__faq-list">
            {faq_content_second.map((item, index) => (
              <Accordion key={index} item={item} number={index + 1} />
            ))}
          </div>
        </div>
      </section>

      <section className="container-fluid synch__project">
        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <p className="synch__project-intro">Jakie projekty realizowaliśmy?</p>
          <div className="row synch__project-row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="synch__project-desc">
                <p>
                  <strong>
                    Wśród naszych projektów warto wymienić synchronizator
                    stworzony dla firmy Aifo,
                  </strong>{" "}
                  oferującej rozwiązania i komponenty dla producentów urządzeń
                  chłodniczych i gastronomicznych. Dzięki naszemu rozwiązaniu,
                  Aifo mogło usprawnić swoje procesy biznesowe i zwiększyć
                  efektywność swoich działań.
                </p>
              </div>
            </div>
            <div className="col-md-6 pl-md-0 order-1 order-md-2">
              <div className="synch__project-img">
                <img src={require("../assets/img/synch/zdj4.jpg")} alt="" />
              </div>
            </div>
          </div>
          <div className="row synch__project-row">
            <div className="col-md-6 pr-md-0">
              <div className="synch__project-img">
                <img src={require("../assets/img/synch/zdj11.jpg")} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="synch__project-desc">
                <p>
                  <strong>
                    Opracowaliśmy również dla naszego klienta w ramach projektu
                    współfinansowanego przez Ministerstwo Cyfryzacji projekt
                    integracji mający na celu połączenie wielu centrów
                    informacji w jeden hub informacyjny.{" "}
                  </strong>
                  System w sposób automatyczny przetwarza dane dotyczące
                  specjalizacji wg PKD z KRS, CEIDG oraz GUS a następnie firmy
                  te zostają dodane do bazy firm CRM. System CRM jest również
                  naszego autorstwa.
                </p>
              </div>
            </div>
          </div>
          <div className="row synch__project-row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="synch__project-desc">
                <p>
                  <strong>
                    Ponadto, współpracowaliśmy z firmą z branży BHP,
                  </strong>{" "}
                  dla której stworzyliśmy synchronizator, który łączył kilka
                  różnych plików z danymi w celu utworzenia jednego kompletnego
                  pliku.{" "}
                  <strong>
                    Mamy napisaną również integrację wymiany danych dla branży
                    deweloperskiej pomiędzy{" "}
                    <Link to="/integracja-vox-developer-crm-z-strona-www/">
                      stronami internetowymi a systemem CRM VOX Developer
                    </Link>
                  </strong>{" "}
                  z sukcesem zastosowaną w wielu projektach deweloperskich.
                </p>
                <p className="mt-5">
                  Nasze doświadczenie w realizacji tego rodzaju projektów
                  pozwala nam na dostarczenie rozwiązań dopasowanych do
                  specyficznych potrzeb naszych klientów.
                </p>
              </div>
            </div>
            <div className="col-md-6 pl-md-0 order-1 order-md-2">
              <div className="synch__project-img">
                <img src={require("../assets/img/synch/zdj10.jpg")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid synch__process">
        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <p className="synch__process-intro">
            Jakie aspekty należy wziąć pod uwagę przed rozpoczęciem procesu
            integracji?
          </p>
          <p className="synch__process-subintro">
            W trakcie realizacji projektów integracyjnych, napotykamy różnorodne
            wyzwania, które wymagają indywidualnego podejścia. Istnieje kilka
            kluczowych kwestii, które warto przedyskutować z klientem przed
            przystąpieniem do pracy. Oto najistotniejsze z nich:
          </p>
          <div className="synch__process-table">
            {process_content.map(({ title, desc }, index) => {
              return (
                <div key={index} className="synch__process-row">
                  <p className="synch__process-title">{title}</p>
                  <p className="synch__process-desc">{desc}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="container-fluid synch__calc">
        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <p className="synch__calc-intro">Jak się rozliczamy?</p>
          <p className="synch__calc-subintro">
            Rozliczenie prac jest ważnym aspektem każdego projektu dlatego prace
            nad synchronizatorami realizujemy w kilku modelach
          </p>
        </div>
        <div className=" col-12 offset-xl-1 col-xl-10">
          <div className="synch__calc-table">
            {calc_content.map(({ icon, title, desc }, index) => {
              return (
                <div key={index} className="synch__calc-item">
                  <img src={icon} alt="" />
                  <p className="synch__calc-title">{title}</p>
                  <p
                    className="synch__calc-desc"
                    dangerouslySetInnerHTML={{ __html: desc }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <p className="synch__calc-text">
            <strong>
              W naszych projektach najczęściej stosujemy model godzinowy.
            </strong>{" "}
            Oznacza to, że naliczamy opłaty za pracę wykonaną przez naszych
            specjalistów w danym przedziale czasowym. Dzięki temu klient płaci
            tylko za rzeczywisty czas poświęcony na projekt, a nie za szacowane
            godziny lub pakiet usług.
          </p>
          <p className="synch__calc-text">
            <strong>
              Podczas współpracy klienci otrzymują dostęp do narzędzi do stałego
              monitorowania postępu pracy naszego zespołu.
            </strong>{" "}
            Dzięki temu mają wgląd w to, ile godzin zostało poświęcone na dany
            etap projektu i jakie zadania zostały wykonane oraz ile trwały.
            Możliwość monitorowania postępu pracy pozwala na szybsze reagowanie
            na ewentualne opóźnienia i zwiększa przejrzystość procesu.
          </p>
          <p className="synch__calc-text">
            <strong>
              Dodatkowo, nasz system rozliczeń prac jest bardzo elastyczny.
            </strong>{" "}
            Daje klientom możliwość zmiany zakresu prac na bieżąco, bez
            konieczności negocjowania nowych warunków umowy. Klienci płacą tylko
            za wykonaną pracę, więc mogą dostosować projekt do bieżących potrzeb
            swojego biznesu.
          </p>
          <p className="synch__calc-text">
            <strong>
              Korzystając z naszego systemu rozliczeń prac, klienci mają również
              pewność, że dostaną dokładny i szczegółowy raport za wykonane
              prace.
            </strong>{" "}
            Rozliczenie wynika z wcześniej zleconych i akceptowanych w systemie
            zadań. Nasz system rozliczeń pozwala na ścisłe kontrolowanie kosztów
            oraz umożliwiając dostęp do szczegółowych raportów o postępach
            projektu w trybie online.
          </p>
        </div>
      </section>

      <section className="container-fluid synch__tech">
        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <p className="synch__tech-intro">W jakich technologiach działamy?</p>
          <p className="synch__tech-text">
            <strong>
              Nasza firma korzysta z różnych technologii jak python, node.js,
              react.js jednak w przypadku synchronizacji danych opieramy się
              głównie o język PHP oraz framework Laravel, aby tworzyć szybkie,
              niezawodne i bezpieczne oprogramowanie dedykowane.
            </strong>{" "}
            Dzięki naszemu doświadczeniu w tych technologiach, jesteśmy w stanie
            dostarczyć produkty najwyższej jakości, zgodne z oczekiwaniami
            naszych klientów.
          </p>
        </div>
      </section>

      <section className="container-fluid synch__contact">
        <div className="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <p className="synch__contact-intro">
            Zapraszamy do skorzystania z naszych usług i dołączenia do grona
            zadowolonych klientów.
          </p>
          <p className="synch__contact-text">
            Skontaktuj się z nami już dziś, aby rozpocząć współpracę i odkryć,
            jak możemy pomóc Twojej firmie w osiągnięciu sukcesu. Zostaw nam
            swoje dane kontaktowe, a nasi specjaliści skontaktują się z Tobą w
            ciągu 24 godzin, aby odpowiedzieć na Twoje pytania i omówić
            szczegóły naszej oferty. Zaufaj naszej wiedzy i doświadczeniu, aby
            zwiększyć skuteczność swojego biznesu!
          </p>

          <div className="synch__contact-form">
            <FormLandingPages
              locales={locales[lang]}
              lang={lang}
              landing_page="synchronizacje"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SynchLandingPage;
